import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import Select from '@mui/material/Select';


const FilterDialog = ({ open, handleClose, applyFilters }) => {
  const [status, setStatus] = useState("");
  const [region, setRegion] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [industry, setIndustry] = useState("");
  const [showCompleted, setShowCompleted] = useState(false);

  const handleApply = () => {
    applyFilters({ status, region, industry ,minValue, maxValue, showCompleted });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "sm",
          width: "100%",
          padding: 1,
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h5" sx={{ mb: 0.5, fontWeight: "bold" }}>
          Filter Deals
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "gray" }}>
          Apply filters to refine your search results
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
      <FormControl fullWidth margin="normal" sx={{ bgcolor: "#f7f7f7" }}>
          <InputLabel id="industry">Industry</InputLabel>
          <Select labelId="industry" value={industry} onChange={(e) => setStatus(e.target.value)}>
            <MenuItem value="wind-offshore">Wind Offshore</MenuItem>
            <MenuItem value="wind-onshore">Wind Onshore</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal" sx={{ bgcolor: "#f7f7f7" }}>
          <InputLabel>Status</InputLabel>
          <Select value={status} onChange={(e) => setStatus(e.target.value)}>
            <MenuItem value="bidding">Bidding</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal" sx={{ bgcolor: "#f7f7f7" }}>
          <InputLabel>Region</InputLabel>
          <Select value={region} onChange={(e) => setRegion(e.target.value)}>
            <MenuItem value="na">North America</MenuItem>
            <MenuItem value="eu">Europe</MenuItem>
            <MenuItem value="asia">Asia</MenuItem>
          </Select>
        </FormControl>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField fullWidth margin="normal" label="Min Value" type="number" value={minValue} onChange={(e) => setMinValue(e.target.value)} sx={{ bgcolor: "#f7f7f7" }} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth margin="normal" label="Max Value" type="number" value={maxValue} onChange={(e) => setMaxValue(e.target.value)} sx={{ bgcolor: "#f7f7f7" }} />
          </Grid>
        </Grid>
        <FormControlLabel control={<Checkbox checked={showCompleted} onChange={(e) => setShowCompleted(e.target.checked)} />} label="Show Completed Deals" />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            color: "black",
            borderRadius: "6px",
            fontSize: 16,
            padding: "10px 20px",
            width: "180px",
            height: "60px",
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleApply}
          variant="contained"
          sx={{
            bgcolor: "black",
            color: "white",
            borderRadius: "6px",
            "&:hover": {
              bgcolor: "black",
              color: "white",
            },
            width: "180px",
            height: "60px",
            fontSize: 16,
            padding: "10px 20px",
          }}
        >
          Apply Filters
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialog;
