import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import { connect, useSelector } from "react-redux";
import { Grid, TextField, Button, Card, CardContent } from "@mui/material";
import { windowHeight } from "../../../utils/utils";

const getDisabled = (value) => {
  switch (value) {
    case "Rôle":
    case "Name":
    case "Prénom":
    case "Email":
    case "Organization":
    case "Position":
      return true;
    default:
      return true;
  }
};

const renderTextField = ({ input, label, meta: { touched, error }, ...custom }) => (
  <TextField variant="outlined" label={label} error={touched && error} helperText={touched && error} {...input} {...custom} />
);

const validate = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "Required";
  }
  if (!values.lastName) {
    errors.lastName = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^.+@.+$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.age) {
    errors.age = "Required";
  } else if (isNaN(Number(values.age))) {
    errors.age = "Must be a number";
  } else if (Number(values.age) < 18) {
    errors.age = "Sorry, you must be at least 18 years old";
  }
  return errors;
};

let ContactForm = (props) => {
  const { handleSubmit } = props;
  const user = useSelector((state) => state.auth?.user);

  const [formValues, setFormValues] = useState({
    name: user?.name || "",
    email: user?.email || "",
    organization_name: user?.organization_info?.name || "DueDil",
    position: user?.organization_info?.position || "Guest",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        {/* First Row */}
        <Grid item xs={12} sm={6}>
          <Card sx={{ backgroundColor: "#1AB5B8", width: "480px" }}>
            <CardContent>
              <TextField
                name="name"
                variant="outlined"
                label="Full name"
                type="text"
                onChange={handleInputChange}
                value={formValues.name}
                disabled={getDisabled("name")}
                fullWidth
                
                
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card sx={{ backgroundColor: "#F5F5F7", width: "480px" }}>
            <CardContent>
              <TextField
                name="email"
                variant="outlined"
                label="Email"
                type="text"
                onChange={handleInputChange}
                value={formValues.email}
                disabled={getDisabled("Email")}
                fullWidth
              />
            </CardContent>
          </Card>
        </Grid>

        {/* Second Row */}
        <Grid item xs={12} sm={6}>
          <Card sx={{ backgroundColor: "#F5F5F7",  width: "480px" }}>
            <CardContent>
              <TextField
                name="organization_name"
                variant="outlined"
                label="Organization"
                type="text"
                onChange={handleInputChange}
                value={formValues.organization_name}
                disabled={getDisabled("Organization")}
                fullWidth
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card sx={{ backgroundColor: "#B81A80", color: "white", width: "480px" }}>
            <CardContent>
              <TextField
                name="position"
                variant="outlined"
                label="Position"
                type="text"
                onChange={handleInputChange}
                value={formValues.position}
                disabled={getDisabled("Position")}
                fullWidth
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactForm;
