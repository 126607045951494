import { useEffect, useState } from "react";
import { Grid, IconButton, InputBase, Button, Select, MenuItem, Tooltip, Box, FormControl, Input, InputLabel, TextField } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { zoomLevels } from "./usePdfViewer";
import { borderColors } from "./pdfColors";
import { windowHeight } from "../../utils/utils";
import { ChevronDown, ChevronUp, ZoomIn, ZoomOut } from "lucide-react";

function PDFOptionsBar({ file, scrolledIndex, numPages, scaleText, nextPage, prevPage, handleZoomIn, handleZoomOut, goToPage, setZoomLevel, zoomInEnabled, zoomOutEnabled, documents, changeDocument }) {
  const [zoomPopoverOpen, setZoomPopoverOpen] = useState(false);
  const [inputValue, setInputValue] = useState(`${scrolledIndex + 1}`);

  useEffect(() => {
    setInputValue(`${scrolledIndex + 1}`);
  }, [scrolledIndex]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const value = parseInt(inputValue, 10);
      if (!isNaN(value) && value > 0) {
        goToPage(value - 1);
      }
    }
  };

  const handleZoomChange = (event) => {
    setZoomLevel(event.target.value);
    setZoomPopoverOpen(false);
  };

  const styles = {
    inputBase: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "50px",
      minWidth: "20px",
      textAlign: "center",
      border: "1px solid #007BFF",
      borderRadius: 1,
      backgroundColor: "#FFFFFF",
      mr: 1,
      color: "#000000",
      "& input": {
        color: "#000000",
        padding: 0,
        textAlign: "center",
        width: "100%",
      },
    },
    iconButton: {
      color: "#007BFF",
      "&:hover": {
        backgroundColor: "rgba(0, 123, 255, 0.1)",
      },
    },
    button: {
      color: "#007BFF",
      height: windowHeight / 21,
      "&:hover": {
        backgroundColor: "rgba(0, 123, 255, 0.2)",
      },
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 1,
        backgroundColor: "#F5F5F7",
        borderRadius: 2,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
      <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
        {!file && (
          <InputLabel id="document-select-label">Select Document</InputLabel>
        )}
        <Select
          labelId="document-select-label"
          value={file}
          onChange={changeDocument}
          displayEmpty // To allow empty display if no value is selected
          renderValue={(selected) => (selected ? selected.name : "Select Document")}
          inputProps={{ 'aria-label': 'Select Document' }}
        >
          {documents
            ?.flatMap((transaction) => transaction.documents)
            .map((doc) => (
              <MenuItem key={doc.id} value={doc}>
                {`${doc.name}`}
              </MenuItem>
            ))}
        </Select>
      </FormControl>


      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Previous Page">
          <IconButton onClick={prevPage} disabled={scrolledIndex === 0} size="large">
            <ChevronUp />
          </IconButton>
        </Tooltip>
        <TextField value={inputValue} onChange={handleChange} onKeyDown={handleKeyDown} variant="outlined" size="small" sx={{ width: 50, mr: 1 }} inputProps={{ style: { textAlign: "center" } }} />
        <span>of {numPages}</span>
        <Tooltip title="Next Page">
          <IconButton onClick={nextPage} disabled={scrolledIndex === numPages - 1} size="large">
            <ChevronDown />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Zoom Out">
          <IconButton onClick={handleZoomOut} disabled={!zoomOutEnabled} size="large">
            <ZoomOut />
          </IconButton>
        </Tooltip>
        <Select value={scaleText} onChange={handleZoomChange} displayEmpty variant="outlined" size="small" inputProps={{ "aria-label": "Zoom level" }} sx={{ mx: 1, minWidth: 90 }}>
          {zoomLevels.map((zoom, index) => (
            <MenuItem key={index} value={zoom}>
              {zoom}
            </MenuItem>
          ))}
        </Select>
        <Tooltip title="Zoom In">
          <IconButton onClick={handleZoomIn} disabled={!zoomInEnabled} size="large">
            <ZoomIn />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default PDFOptionsBar;
