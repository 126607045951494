import React, { useState } from "react";
import { Box, Paper, Grid, Typography } from "@mui/material";

import GeneralParameters from "./components/GeneralParameters";
import LogOut from "./components/LogOut";
import { colors } from "../../utils/colors";
import { windowHeight } from "../../utils/utils";
import { useSelector } from "react-redux";
import RequestFeature from "./components/RequestFeature";

const TabOption = ({ setSelected, selected, label, index }) => (
  <Grid item xs={6} sm={6} md={3}>
    <Box sx={{ cursor: "pointer", textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }} onClick={() => setSelected(index)}>
      <Typography color={selected === index ? colors.BLUE : colors.GRAY_ONE}>{label}</Typography>
      <Box
        sx={{
          width: "90%",
          bgcolor: selected === index ? colors.BLUE : "transparent",
          height: "3px",
          mt: "25px",
          borderRadius: "full",
        }}
      />
    </Box>
  </Grid>
);

const SettingsScreen = () => {
  const open = useSelector((state) => state.navBar.is_open);
  const [selected, setSelected] = useState(1);

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ height: "100vh" }}>
      <Grid item xs={10} md={8} lg={8} sx={{ p: 2, transition: "margin 0.5s", marginLeft: open ? "240px" : "75px" }}>
        <Paper sx={{bgcolor: colors.WHITE, mb: 2}}>
          <Grid container justifyContent="center" alignItems="center" sx={{ pt: windowHeight / 320 }}>
            <TabOption setSelected={setSelected} selected={selected} label="General parameters" index={1} />
            <TabOption setSelected={setSelected} selected={selected} label="Request feature" index={2} />
            <TabOption setSelected={setSelected} selected={selected} label="Logout" index={4} />
          </Grid>
        </Paper>
        <Paper sx={{ height: windowHeight / 1.6, bgcolor: colors.WHITE, boxShadow: "none"}}>
          {selected === 1 && <GeneralParameters />}
          {selected === 2 && <RequestFeature />}
          {selected === 4 && <LogOut />}
          {/* Add other components for other tabs */}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SettingsScreen;
