import React, { useEffect, useState } from "react";
import { Button, ButtonBase, Card, Divider, Typography, CardContent, Table, Select, MenuItem, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, TablePagination, Checkbox, InputAdornment, IconButton, TextField, Tooltip, Grid, Box } from "@mui/material";
// import { Select, MenuItem } from "@mui/material";

import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SearchBar from "../../components/SearchBar";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { CloudDownload, Eye, MessageCircle, Repeat, Trash } from "lucide-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"; // Import at the top of your file
import BackButton from "../../components/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { addDocumentToDocumentsWithTransactions, addDocuments, clearDocuments, deleteDocumentFromTransaction, fetchDocuments, setDocuments, updateDocumentProperty } from "../../redux/actions/documentsAction";
import DeleteConfirmModal from "../../components/DeleteConfirmModal";
import { formatDate, getAllDocumentTypes, handleDownloadDocument, isMobile } from "../../utils/utils";
import AddDocumentModal from "../../components/AddDocumentsModel";
import { fetchTransaction, updateTransactionDocumentUploaded, updateTransactionDocumentsIds, updateTransactionProperty } from "../../redux/actions/transactionAction";
import { document_types_categories } from "../../api/utils/dataModel";
import ModalPdfView from "../../components/ModalPdfView";
import { useSnackbar } from "notistack";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { api_subscription_key, backendUrl } from "../../config";
import { store } from "../../redux/store";
import { colors } from "../../utils/colors";
import { backendClient } from "../../api/backend";
import Documentstart from "../../components/Documentstart";

const truncateSummary = (summary, maxLength = 150) => {
  if (!summary) return "N/A";
  if (summary.length <= maxLength) return summary;
  return `${summary.substring(0, maxLength - 3)}...`;
};

const peersCards = [
  { labels: ["Project Fly"], risk: ["£16m"] },
  { labels: ["Project Atlantis"], risk: ["£30m"] },
  { labels: ["Project Phoenix"], risk: ["$13m"] },
  { labels: ["Project Fly Tranche 2"], risk: ["$18m"] },
  { labels: ["Card 5"], risk: ["£10m"] },
  { labels: ["Card 6"], risk: ["£8m"] },
  { labels: ["Card 7"], risk: ["£0m"] },
  { labels: ["Card 8"], risk: ["£102m"] },
];
const PeerComponent = ({ name, risk }) => {
  return (
    <Card
      sx={{ height: "80px", width: "200px", bgcolor: "#FFE4E1", position: "relative" }}
      style={{
        boxShadow: "none",
        backgroundColor: "#f7f7f7",
        borderRadius: "22px",
        color: "black",
        cursor: "pointer",
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          component="div"
          sx={{
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {name}
        </Typography>
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          {risk}
        </Typography>
      </CardContent>
      <Box
        sx={{
          position: "absolute",
          bottom: 8,
          right: 8,
          display: "flex",
          gap: 0.5,
        }}
      >
        <IconButton sx={{ color: "gray" }} size="small">
          <MessageCircle size={12} />
        </IconButton>
        <IconButton sx={{ color: "gray" }} size="small">
          <Trash size={12} />
        </IconButton>
      </Box>
    </Card>
  );
};

const PeersSection = ({ text = "Peers", peers }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5" component="div" sx={{ marginBottom: 2 }}>
          {text}
        </Typography>
      </Grid>
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        {peers && peers.length > 0 ? (
          <>
            {peers.slice(0, 6).map((peer, index) => (
              <Grid item xs={6} sm={4} md={4} key={index} sx={{ padding: 1 }}>
                {peer.length > 0 ? (
                  <PeersSection cards={peersCards} setPeers={() => {}} /> // Render PeersSection when peers exist
                ) : (
                  <Grid container spacing={1}>
                    {/* Reduce spacing between cards */}
                    <Grid item xs={12}>
                      <Typography variant="h5" component="div" sx={{ marginBottom: 8 }}>
                        No {text} data to display
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            ))}
          </>
        ) : (
          <Typography variant="h5" component="div" sx={{ marginBottom: 8 }}>
            No {text} data to display
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

const DocumentsScreen = () => {
  // STATES
  const navigate = useNavigate();
  const { transactionId } = useParams();
  const location = useLocation();
  const documentIds = location.state?.documentIds;
  const transactionName = location.state?.transactionName;
  const [searchQuery, setSearchQuery] = useState("");
  const [orderDirection, setOrderDirection] = useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState("title");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [isDeleteModalVisisble, setIsDeleteModalVisisble] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isLoadingAddDocument, setIsLoadingAddDocument] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isLoadingChangeCategory, setIsLoadingChangeCategory] = useState(false);
  const [isLoadingChangeType, setIsLoadingChangeType] = useState(false);

  //const detailColors = ["#1AB5B8", "#B81A80"];
  //const cardBackgroundColor = detailColors[colorIndex % 2];

  const [document_categories, set_document_categories] = useState(null);
  useEffect(() => {
    set_document_categories(getAllDocumentTypes(document_types_categories));
  }, [document_types_categories]);

  // REDUX
  const dispatch = useDispatch();
  const open = useSelector((state) => state.navBar.is_open);
  const documents = useSelector((state) => state.documents?.documents[0]?.documents);
  const transaction = useSelector((state) => state.transactions.transaction);
  const user = useSelector((state) => state.auth.user);

  console.log("documentXXXs", documents);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const fetch_fn = async () => {
    setIsLoadingDocuments(true);
    await dispatch(clearDocuments());
    await dispatch(fetchDocuments(transactionId, page + 1, rowsPerPage));
    if (totalDocuments === 0) {
      const totalDocuments_ = await backendClient.countDocuments();
      setTotalDocuments(totalDocuments_["count"]);
    }
    await dispatch(fetchTransaction(transactionId));
    setIsLoadingDocuments(false);
  };
  useEffect(() => {
    if (!isLoadingDelete) {
      fetch_fn();
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    console.log("isLoadingDelete", isLoadingDelete);
    console.log("documentsXX", documents);
    if (documents) {
      setFilteredDocuments(
        documents.filter((document) => {
          return (
            document.name?.toLowerCase().includes(searchQuery) ||
            document.document_category?.toLowerCase().includes(searchQuery) ||
            document.document_type?.toLowerCase().includes(searchQuery) ||
            document.document_format?.toLowerCase().includes(searchQuery) ||
            document.date?.toLowerCase().includes(searchQuery) ||
            document.summary?.toLowerCase().includes(searchQuery)
          );
        }),
      );
    }
  }, [documents, searchQuery]);

  const handleRequestSort = (property) => {
    const isAscending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? "desc" : "asc");
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = documents.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex >= 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value.toLowerCase()); // Convert the search query to lowercase for case-insensitive comparison
  };

  const handleDeleteDocument = async () => {
    if (isLoadingDelete || isLoadingDocuments || isLoadingAddDocument || isLoadingChangeCategory || isLoadingChangeType) {
      return;
    }
    setIsDeleteModalVisisble(true);
  };

  const handleConfirmDelete = async () => {
    setIsLoadingDelete(true);
    const results = await dispatch(deleteDocumentFromTransaction(transactionId, selected));
    const response = await results.json();
    if (response?.transaction_deleted) {
      navigate("/data-import/transactions");
    }
    // await fetch_fn();
    setSelected([]);
    setIsDeleteModalVisisble(false);
    setIsLoadingDelete(false);
    enqueueSnackbar("Document deleted!", { variant: "success" });
  };

  const handleCancelDelete = async () => {
    setIsDeleteModalVisisble(false);
  };

  const handleDownload = async (event, document) => {
    event.stopPropagation(); // This stops the event from propagating to parent elements
    handleDownloadDocument(document.url, document.name);
  };

  const [currentDocument, setCurrentDocument] = useState({ url: "", name: "" });
  const [isPdfView, setIsPdfView] = useState(false);

  const handleOpenPdfView = async (event, document) => {
    event.stopPropagation();
    setCurrentDocument({ url: document.url, name: document.name });
    setIsPdfView(true);
  };

  const handleOpen = () => {
    // Set the current document here
    setIsPdfView(!isPdfView);
  };

  const handleClose = () => setIsPdfView(false);

  const handleAddDocument = async () => {
    if (isLoadingDelete || isLoadingDocuments || isLoadingAddDocument || isLoadingChangeCategory || isLoadingChangeType) {
      return;
    }
    setIsAddModalVisible(true);
  };

  const handleCancelAddDocument = async () => {
    setIsAddModalVisible(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const [addDocumentTrigger, setAddDocumentTrigger] = useState(false);

  const handleConfirmAddDocument = async () => {
    if (uploadedFiles.length === 0) {
      enqueueSnackbar("No file selected.", { variant: "error" });
      return;
    }
    setIsLoadingAddDocument(true);
    // upload documents
    const metadata = {};

    // update is uploading document of transction
    await dispatch(updateTransactionDocumentUploaded(transaction, false));

    const uploadedDocumentsStatus = await dispatch(addDocuments(transactionId, uploadedFiles, metadata, true));

    console.log("uploadedDocumentsStatus", uploadedDocumentsStatus);

    // // get documentsIds
    // const documentsIds = uploadedDocuments.map((x) => x.id);
    // // update transaction
    // await dispatch(updateTransactionProperty(transactionId, "documents_ids", documentsIds, true, false));
    // // update documents
    // const updatedDocuments = {
    //   transactionId,
    //   documents: [...documents, ...uploadedDocuments],
    // };
    // await dispatch(setDocuments([updatedDocuments]));

    setIsLoadingAddDocument(false);
    setIsAddModalVisible(false);
    setAddDocumentTrigger(Math.random());
    // enqueueSnackbar("Document added!", { variant: "success" });
    enqueueSnackbar("Uploading documents...");
  };

  useEffect(() => {
    console.log("EFFECTPP", transaction);
    if (!transaction?.document_uploaded || typeof addDocumentTrigger === "number") {
      console.log("INSIDE");
      const NewController = new AbortController();
      const { signal } = NewController;

      const url = `${backendUrl}api/document/events_upload_files/?session_id=${transactionId}`;

      const state = store.getState();
      const token = state.auth.accessToken;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Ocp-Apim-Subscription-Key": api_subscription_key,
      };

      fetchEventSource(url, {
        method: "GET",
        signal: signal,
        headers,
        onmessage: async (event) => {
          console.log("EVENT.DATA", event.data);
          let parsedData;
          try {
            parsedData = JSON.parse(event.data);
          } catch (error) {
            console.error("Error parsing JSON:", error);
            parsedData = null;
          }

          if (parsedData) {
            console.log("parsedData", parsedData);
            if (parsedData.status === "data") {
              console.log("EVENTSOURCE data status...");
              console.log("parsedData second", parsedData);
              await dispatch(updateTransactionDocumentsIds(transactionId, parsedData.data.id));
              await dispatch(addDocumentToDocumentsWithTransactions(parsedData.data, transactionId));
              // setUploadMessage(`Document ${parsedData.data.name} uploaded!`);
              enqueueSnackbar(`Document ${parsedData.data.name} uploaded!`, { variant: "success" });
              // setNumberOfDocumentsUploaded((prev) => prev + 1);
            } else if (parsedData.status === "complete") {
              console.log("EVENTSOURCE closing connection complete...");
              enqueueSnackbar(`All documents has been uploaded!`, { variant: "success" });
              // eventSource.close();
              NewController.abort();
              // setIsUploadingDocuments(false);
            } else if (parsedData.status !== "ping" && parsedData.status !== "data") {
              console.log("EVENTSOURCE setting status...");
              // setUploadMessage(parsedData.status);
            }
          }
        },
        onopen: async () => {
          console.log("Connection to the server opened.");
        },
        onerror: (error) => {
          console.error("EventSource failed:", error);
          NewController.abort();
        },
        onclose: () => {
          console.log("onClose Triggered");
          NewController.abort();
        },
      });

      // Cleanup function to abort the fetchEventSource when the component unmounts
      return () => {
        NewController.abort();
      };
    }
  }, [dispatch, transaction, addDocumentTrigger]);

  const handleTypeChange = async (event, documentId) => {
    event.stopPropagation(); // This stops the event from propagating to parent elements
    setIsLoadingChangeType(true);
    // update document locally
    await dispatch(updateDocumentProperty(documentId, "document_type", [event.target.value], false, false, true));
    enqueueSnackbar("Document type updated!", { variant: "success" });
    setIsLoadingChangeType(false);
  };

  const handleCategoryChange = async (event, documentId) => {
    event.stopPropagation(); // This stops the event from propagating to parent elements
    setIsLoadingChangeCategory(true);
    // update document locally
    await dispatch(updateDocumentProperty(documentId, "document_category", [event.target.value], false, false, true));
    enqueueSnackbar("Document category updated!", { variant: "success" });
    setIsLoadingChangeCategory(false);
  };

  const handleEditClick = (event, documentId) => {
    event.stopPropagation(); // This stops the event from propagating to parent elements
    // Set 'isEditing' to true for the document with the given ID
    // This will switch the cell to the editable mode
  };

  const getTitle = (value, maxLength = 24, ellipsis = "...") => {
    if (typeof value !== "string") {
      console.warn(`getTitle received a non-string value: ${typeof value}`);
      return "";
    }

    const trimmedValue = value.trim();

    if (trimmedValue.length <= maxLength) {
      return trimmedValue;
    }

    const truncated = trimmedValue.slice(0, maxLength - ellipsis.length);
    return truncated.trim() + ellipsis;
  };
  const handleBackClick = () => {
    navigate(-1);
  };

  function applyEllipsis(labelElement, maxLength) {
    // if (!labelElement) return;
    console.log(labelElement);
    if (labelElement.length > maxLength) {
      // Truncate the text and add ellipsis
      labelElement = labelElement.substring(0, maxLength - 3) + "...";
    }
    return labelElement;
  }

  return (
    <Documentstart
      title={transaction ? `${transaction.name} - Documents` : "Documents"}
      open={open}
      headerProps={{ searchQuery: searchQuery, setSearchQuery: setSearchQuery, showFilter: false, showView: false, backbutton: true, handleBackClick: handleBackClick, custombutton: true, custombuttontext: "Add documents", custombuttononclick: handleAddDocument, disabled: isLoadingAddDocument }}
    >
      {/* Delete and Add Document Modals */}
      <DeleteConfirmModal isLoadingDelete={isLoadingDelete} type={"Documents"} open={isDeleteModalVisisble} handleClose={handleCancelDelete} handleConfirm={handleConfirmDelete} />
      <AddDocumentModal uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} isLoadingAddDocument={isLoadingAddDocument} open={isAddModalVisible} handleClose={handleCancelAddDocument} handleConfirm={handleConfirmAddDocument} />
      <ModalPdfView open={isPdfView} setOpen={handleOpen} url={currentDocument.url} handleClose={handleClose} />

      {/* HEADER */}

      {/* Document Cards */}
      {isLoadingDocuments ? (
        <Box sx={{ display: "flex", justifyContent: "center", padding: "10px" }}>
          <CircularProgress size={48} thickness={4} />
        </Box>
      ) : (
        <Grid container spacing={1} sx={{ display: "flex", flexDirection: "column", overflowX: "hidden" }}>
          {filteredDocuments?.map((document, index) => (
            <Grid item xs={12} sm={6} md={4} key={document.id}>
              {/* Card with conditional background color */}
              <Card
                sx={{
                  p: 2,
                  width: "90vw",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "none",

                  // Set text color to white to contrast with the background
                }}
              >
                {/* Title and Actions */}
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
                  <ButtonBase sx={{ justifyContent: "flex-start", display: "flex", alignItems: "center", width: "50%" }}>
                    <Checkbox checked={selected.includes(document.id)} onClick={(event) => handleClick(event, document.id)} sx={{ mr: 2 }} />
                    <Typography variant="h5" component="div" sx={{ fontWeight: "bold", cursor: "pointer", color: index % 2 === 0 ? "#1AB5B8" : "#B81A80" }}>
                      {applyEllipsis(document.name, 20)}
                    </Typography>
                  </ButtonBase>

                  {/* Action Buttons */}
                  <Box sx={{ display: "flex", gap: 2, alignItems: "center", ml: "auto" }}>
                    <Button variant="text" sx={{ width: "150px", textTransform: "none", padding: 2, fontSize: "16px", color: "black" }}>
                      Type: {document.document_type}
                    </Button>
                    <Button variant="text" sx={{ width: "150px", textTransform: "none", padding: 2, fontSize: "16px", color: "black" }}>
                      Format: {document.document_format}
                    </Button>
                    <Button variant="text" sx={{ width: "350px", textTransform: "none", padding: 2, fontSize: "16px", color: "black" }}>
                      Category: {document.document_category}
                    </Button>
                    <Button startIcon={<VisibilityIcon />} variant="text" sx={{ width: "auto", textTransform: "none", padding: 2, fontSize: "16px", color: "black" }} onClick={(event) => handleOpenPdfView(event, document)}>
                      View
                    </Button>
                    <Button startIcon={<CloudDownloadIcon />} variant="text" sx={{ width: "150px", textTransform: "none", padding: 2, fontSize: "16px", color: "black" }} onClick={(event) => handleDownload(event, document)}>
                      Download
                    </Button>
                  </Box>
                </Box>

                {/* Summary and Peers Section */}
                <Grid container spacing={2} sx={{ height: "100%" }}>
                  {" "}
                  {/* Adjusted spacing between the grid items */}
                  <Grid item xs={12} sm={3} sx={{ height: "100%" }}>
                    <Card
                      sx={{
                        height: "170px",
                        width: "350px" /* Adjusted width to make the card narrower */,
                        boxShadow: "none",
                        borderRadius: "22px",
                        marginTop: "45px",
                      }}
                    >
                      <CardContent sx={{ height: "100%", backgroundColor: index % 2 === 0 ? "#1AB5B8" : "#B81A80" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%" }}>
                          <Typography variant="h5" component="div" sx={{ fontWeight: "bold", textAlign: "center", color: "white" }}>
                            Summary
                          </Typography>
                          <Divider sx={{ width: "100%", my: 2, color: "white" }} />
                          <Typography variant="body2" sx={{ textAlign: "center", color: "white" }}>
                            {truncateSummary(document.summary) || "N/A"}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={8} sx={{ height: "100%", marginLeft: "16px" }}>
                    {" "}
                    {/* Added margin to create spacing */}
                    {/* Ensure PeersSection is responsive */}
                    <PeersSection peers={document.peers} text="KPIs" />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Documentstart>
  );
};

export default DocumentsScreen;
