import { setActivePdf, setPdfLoadingTask } from "../../redux/actions/documentsAction";
import ViewPdf from "./ViewPdf";
import { useMultiplePdfs } from "./useMultiplePdfs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as pdfjsLib from "pdfjs-dist/webpack";
import { usePdfFocus } from "./pdfContext";
import { getDocumentById } from "../../utils/utils";
import { Paper } from "@mui/material";

function DisplayMultiplePdfs({ isUploadingDocuments, is_conversation, handleRemoveDocument, pdfWidth, pdfHeight }) {
  const transactions = useSelector((state) => state.transactions.transactions);
  const documentsIds = transactions.flatMap((transaction) => transaction.documents_ids);
  const dispatch = useDispatch();
  const loadingTask = useSelector((state) => state.documents.loadingTask);
  const { pdfFocusState } = usePdfFocus();
  // console.log("OK------ documentsIds", documentsIds);

  const activePdf = useSelector((state) => state.documents.activePdf);

  // const [file, setFile] = useState(null);
  const [isFileReady, setIsFileReady] = useState(false); // New state to track if file is ready
  const documents = useSelector((state) => state.documents.documents);
  console.log("dcouments ", documents);
  useEffect(() => {
    console.log("HEROOO");
    const set_fn = async () => {
      if (documents.length > 0 && documents[0].documents.length > 0) {
        setIsFileReady(false);
        const firstFile = documents[0].documents[0];
        if (loadingTask) {
          try {
            await loadingTask.destroy();
          } catch (error) {
            console.error("Error destroying previous PDF loading task: ", error);
          }
        }
        const newLoadingTask = pdfjsLib.getDocument(documents[0].documents[0].url);
        await dispatch(setPdfLoadingTask(newLoadingTask));
        // setFile(firstFile);
        await dispatch(setActivePdf(firstFile));

        setIsFileReady(true);
        console.log("HERAA");
      }
    };
    set_fn();
  }, [documents]);

  useEffect(() => {
    const set_fn = async () => {
      // get the exct document inside documents.docuemnts matching pdfFocusState.documentId in documents object
      const document = getDocumentById(documents, pdfFocusState.documentId);

      await dispatch(setActivePdf(document));
    };

    set_fn();
  }, [pdfFocusState]);

  // console.log("activePdfIdXX", activePdfId);
  // useEffect(() => {
  //   console.log("TRIGGER");
  //   if (activePdfId && documents.length > 0) {
  //     let activeDocument = documents.find((document) => document.documents.some((doc) => doc.id === activePdfId));
  //     console.log("activeDocument", activeDocument);
  //     // get the exact document inside the transaction where the activePdfId is located
  //     activeDocument = activeDocument?.documents.find((doc) => doc.id === activePdfId);

  //     if (activeDocument) {
  //       console.log("FILOXXX", activeDocument);
  //       setFile(activeDocument);
  //     }
  //   }
  // }, [activePdfId]);

  // console.log("activePdfId", activePdfId);
  return (
    <>
      {/* PDF */}
      <Paper elevation={2} className={`flex flex-grow flex-col w-full"} h-full p-2`}>
        <>
          {/* <span>DEBUG</span> */}
          <ViewPdf index={0} file={activePdf} documents={documents} isFileReady={isFileReady} is_conversation={is_conversation} pdfWidth={pdfWidth} pdfHeight={pdfHeight} handleRemoveDocument={handleRemoveDocument} />
          {/* TAB */}
          {/* <MemoizedVirtualizedPDF is_conversation={is_conversation} ref={pdfFocusRef} file={file} setIndex={setCurrentPageNumber} scale={scale} setScaleFit={setScaleFit} setNumPages={setNumPages} pdfWidth={pdfWidth} pdfHeight={pdfHeight} />} */}
        </>
      </Paper>
    </>
  );
}

export default DisplayMultiplePdfs;
