import { InputAdornment } from "@material-ui/core";
import { Box, Button, Divider, IconButton, TextField, Typography, Tooltip } from "@mui/material";
import { ChevronLeft, LayoutGrid, Search, X, Trash } from "lucide-react";
import React, { useState } from "react";
import FilterDialog from "./FilterDialog";
import SearchBar from "./SearchBar";
import CustomButton from "./CustomButton";

const Header = ({
  headerText = "Deals", // Customizable header text
  searchQuery,
  setSearchQuery,
  handleSearchChange,
  handleClearSearch,
  showFilter = true, // Toggle visibility for Filter
  showView = true, // Toggle visibility for View
  showSearchBar = true, // Toggle visibility for SearchBar
  custombutton = false,
  custombuttononclick = () => {},
  custombuttontext = "Add KPI",
  iconbutton = <></>,
  backbutton = false,
  handleBackClick = () => {},
  disabled = false,
  conversationmgmt = <></>,
  showDeleteButton = false, // Conditionally show Delete button
  handleDeleteSelected = () => {}, // Handle delete
  ...rest
}) => {
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);

  const handleOpenFilterDialog = () => {
    setFilterDialogOpen(true);
  };

  const handleCloseFilterDialog = () => {
    setFilterDialogOpen(false);
  };

  const handleApplyFilters = (filters) => {
    console.log("Applied filters:", filters);
    // Here you would typically update your state or call a function to filter the deals
  };

  return (
    <Box sx={{ margin: 0 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
          {backbutton && (
            <Button
              startIcon={<ChevronLeft color="black" />}
              onClick={handleBackClick}
              sx={{
                marginTop: 2,
                marginBottom: 2,
                marginLeft: 2,
                fontWeight: "bold",
                color: "black",
              }}
            >
              Back
            </Button>
          )}
          <Typography
            variant="h4"
            sx={{
              marginTop: 2,
              marginBottom: 2,
              fontWeight: "bold",
              color: "black",
            }}
          >
            {headerText} {/* Customizable Header Text */}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          {showFilter && ( // Conditionally render Filter button
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button variant="text" sx={{ textTransform: "none", color: "black", padding: 1, fontSize: "16px", gap: 1 }} onClick={handleOpenFilterDialog}>
                <Typography variant="body1" fontWeight={"bold"}>
                  Filter
                </Typography>
                <LayoutGrid color="black" />
              </Button>
            </Box>
          )}

          {showView && ( // Conditionally render View button
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Feature coming soon">
                <Button variant="text" sx={{ textTransform: "none", color: "black", padding: 1, fontSize: "16px", gap: 1 }}>
                  <Typography variant="body1" fontWeight={"bold"}>
                    View
                  </Typography>
                  <LayoutGrid color="black" />
                </Button>
              </Tooltip>
            </Box>
          )}
          {custombutton && (
            <Box>
              <Tooltip title={custombuttontext === "Create Document" ? "Feature coming soon" : ""} arrow>
                <div>
                  {" "}
                  {/* Wrapping the button in a div to allow Tooltip on disabled button */}
                  <CustomButton onClick={custombuttononclick} backgroundColor="black" color="white" hoverBackgroundColor="black" endIcon={iconbutton} disabled={disabled} {...rest}>
                    {custombuttontext}
                  </CustomButton>
                </div>
              </Tooltip>
            </Box>
          )}
          {conversationmgmt}

          <FilterDialog open={filterDialogOpen} handleClose={handleCloseFilterDialog} applyFilters={handleApplyFilters} />
        </Box>
      </Box>

      <Divider sx={{ marginTop: 1, marginBottom: 2, borderBottomWidth: 3, bgcolor: "#000" }} />

      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 24px", marginBottom: "20px" }}>
        {showSearchBar && <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />}
        {showDeleteButton && (
          <Button
            startIcon={<Trash />}
            variant="contained"
            disabled={true}
            sx={{
              bgcolor: "red",
              color: "white",
              width: "150px",
              height: "50px",
              "&:hover": { bgcolor: "darkred" },
              ml: 2,
            }}
            onClick={handleDeleteSelected}
          >
            Delete
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Header;
