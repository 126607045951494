import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Card, CardMedia, Divider, Grid, IconButton, Menu, MenuItem, Stack, SvgIcon, Typography } from "@mui/material";
import { CirclePlus, CloudDownload, Edit, Eye, FileLock2, Save } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { backendClient } from "../../api/backend";
import excelImage from "../../assets/excel.png";
import CustomButton from "../../components/CustomButton";
import CustomProgressBar from "../DealsScreen/dealscomponents/CustomProgressBar";
import DueDiligenceCard from "../DealsScreen/dealscomponents/duediligencecard";
import WorkstreamModal from "../DealsScreen/dealscomponents/workstreammodal";
import { fetchTemplateWorkstream, fetchTemplateWorkstreams, updateTemplateWorkstream, deleteTemplateWorkstream, countTemplateWorkstreams } from "../../redux/actions/templateworkstreamAction";
import Spinner from "../../components/Spinner";
import { useSnackbar } from "notistack";
import Documentstart from "../../components/Documentstart";

const ExcelIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 512 512">
    <path d="M321.49,244.09l-202.42-35.72v263.94c0,12.05,9.77,21.83,21.83,21.83l0,0h349.28 c12.05,0,21.83-9.77,21.83-21.83l0,0v-97.24L321.49,244.09z" fill="#185C37" />
    <path d="M321.49,17.86H140.9c-12.05,0-21.83,9.77-21.83,21.83l0,0v97.24L321.49,256l107.16,35.72L512,256V136.93 L321.49,17.86z" fill="#21A366" />
    <path d="M119.07,136.93h202.42V256H119.07V136.93z" fill="#107C41" />
    <path d="M263.94,113.12H119.07v297.67h144.87c12.04-0.04,21.79-9.79,21.83-21.83V134.94 C285.73,122.9,275.98,113.16,263.94,113.12z" fill="#000" fillOpacity="0.1" />
    <path d="M252.04,125.02H119.07V422.7h132.97c12.04-0.04,21.79-9.79,21.83-21.83V146.85 C273.82,134.81,264.07,125.06,252.04,125.02z" fill="#000" fillOpacity="0.2" />
    <path d="M252.04,125.02H119.07v273.86h132.97c12.04-0.04,21.79-9.79,21.83-21.83V146.85 C273.82,134.81,264.07,125.06,252.04,125.02z" fill="#000" fillOpacity="0.2" />
    <path d="M240.13,125.02H119.07v273.86h121.06c12.04-0.04,21.79-9.79,21.83-21.83V146.85 C261.91,134.81,252.17,125.06,240.13,125.02z" fill="#000" fillOpacity="0.2" />
    <path d="M21.83,125.02h218.3c12.05,0,21.83,9.77,21.83,21.83v218.3c0,12.05-9.77,21.83-21.83,21.83H21.83 C9.77,386.98,0,377.21,0,365.15v-218.3C0,134.79,9.77,125.02,21.83,125.02z" fill="url(#excel-gradient)" />
    <path
      d="M67.6,326.94l45.91-71.14l-42.07-70.75h33.84l22.96,45.25c2.12,4.3,3.57,7.49,4.36,9.6h0.3 c1.51-3.43,3.1-6.76,4.76-9.99l24.54-44.83h31.07l-43.14,70.33l44.23,71.54H161.3l-26.52-49.66c-1.25-2.11-2.31-4.33-3.17-6.63 h-0.39c-0.78,2.25-1.81,4.41-3.07,6.43l-27.3,49.87L67.6,326.94L67.6,326.94z"
      fill="#FFFFFF"
    />
    <path d="M490.17,17.86H321.49v119.07H512V39.69C512,27.63,502.23,17.86,490.17,17.86L490.17,17.86z" fill="#33C481" />
    <path d="M321.49,256H512v119.07H321.49V256z" fill="#107C41" />
    <defs>
      <linearGradient id="excel-gradient" x1="45.5065" y1="-1464.0308" x2="216.4467" y2="-1167.9695" gradientUnits="userSpaceOnUse" gradientTransform="matrix(1 0 0 1 0 1572)">
        <stop offset="0" stopColor="#18884F" />
        <stop offset="0.5" stopColor="#117E43" />
        <stop offset="1" stopColor="#0B6631" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

const SectionHeaderWithButton = ({ title, buttonText, onButtonClick, phase }) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center" sx={{ width: "100%", mb: 2 }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          color: "black",
          flexShrink: 0,
        }}
      >
        {title}
      </Typography>
      <Divider
        sx={{
          flexGrow: 1,
          borderBottomWidth: 1,
          bgcolor: "#5e5e5d",
        }}
      />
      {/* Render button only for phase 2 */}
      {phase === 2 && (
        <Button
          startIcon={<CirclePlus color="black" />}
          onClick={onButtonClick}
          sx={{
            fontWeight: "bold",
            color: "black",
            flexShrink: 0,
          }}
        >
          {buttonText}
        </Button>
      )}
    </Stack>
  );
};

const DueDiligenceTemplate = ({ setView }) => {
  const [isEditMode, setIsEditMode] = useState(false); // Track if edit mode is active
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState("Wind Onshore");
  const open2 = Boolean(anchorEl);
  const organization_id = useSelector((state) => state.auth.user?.organization_id);
  const industriesPhase1 = ["Wind Onshore"];
  const open = useSelector((state) => state.navBar.is_open);

  const dispatch = useDispatch();
  const { templateWorkstreams, isLoading, error } = useSelector((state) => state.templateWorkstream);
  const state = useSelector((state) => state);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (organization_id) {
      dispatch(fetchTemplateWorkstreams(selectedIndustry, organization_id));
    }
  }, [dispatch, selectedIndustry, organization_id]);

  useEffect(() => {
    console.log("Updated state:", templateWorkstreams);
  }, [templateWorkstreams]);

  console.log("template workstreams", templateWorkstreams);
  console.log("full state", state);

  const workstreamsPhase1 = templateWorkstreams.filter((ws) => ws.phase === "Phase 1");
  const workstreamsPhase2 = templateWorkstreams.filter((ws) => ws.phase === "Phase 2");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (industry) => {
    setSelectedIndustry(industry); // Set the selected industry
    handleClose();
  };

  const handleDetailsClick2 = () => {
    console.log("Details clicked");
    setIsModalOpen(true); // Open the modal when the button is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal when needed
  };

  const handleCardClick = (templateWorkstream) => {
    console.log("click", templateWorkstream);
    if (templateWorkstream.default_questionnaires?.length > 0) {
      const questionnaireId = templateWorkstream.default_questionnaires[0].id;
      console.log(questionnaireId);
      const questionnaires = templateWorkstream.default_questionnaires[0].default_questions; // Extract the full questionnaire object

      navigate(`/questionnairetemplate/${questionnaireId}`, { state: { questionnaires } });

      enqueueSnackbar("Opening Template questionnaire Success", { variant: "success" });
    }
  };
  const handleEditWorkstream = async (id, newTitle) => {
    try {
      // Get the current workstream from the Redux store
      const currentWorkstream = templateWorkstreams.find((workstream) => workstream.id === id);

      // Ensure we have the full workstream object, then update its name
      const updatedWorkstream = {
        ...currentWorkstream, // Copy all existing properties
        name: newTitle, // Overwrite the name with the new title
      };

      // Dispatch the action to update the workstream with the full object
      await dispatch(updateTemplateWorkstream(id, updatedWorkstream));

      // Notify the user
      enqueueSnackbar("Template updated successfully", { variant: "success" });
      console.log(`Successfully updated workstream with id ${id}`);
    } catch (error) {
      console.error(`Failed to update workstream with id ${id}`, error);
      enqueueSnackbar("Error updating template", { variant: "error" });
    }
  };

  const handleDeleteWorkstream = async (id) => {
    try {
      await dispatch(deleteTemplateWorkstream(id)); // Dispatch action to delete the workstream
      console.log(`Successfully deleted workstream with id ${id}`);
    } catch (error) {
      console.error(`Failed to delete workstream with id ${id}`, error);
    }
  };

  return (
    <Documentstart open={open} title={"Due Diligence Template"} headerProps={{ showSearchBar: false, showFilter: false, showView: false, custombutton: true, custombuttononclick: handleClick, custombuttontext: selectedIndustry, iconbutton: <ExpandMoreIcon /> }}>
      <>
        {/* Menu for dropdown */}
        <Menu
          anchorEl={anchorEl}
          open={open2}
          onClose={handleClose}
          PaperProps={{
            sx: {
              width: "350px", // Adjust match to Custombutton width
            },
          }}
        >
          {industriesPhase1.map((industry) => (
            <MenuItem key={industry} onClick={() => handleMenuItemClick(industry)}>
              {industry}
            </MenuItem>
          ))}
        </Menu>

        <Stack direction={"row"} spacing={4} sx={{ paddingTop: 0, marginBottom: 2 }}>
          <Typography
            variant="h5"
            sx={{
              marginTop: 2,
              marginBottom: 2,
              marginLeft: 2,
              fontWeight: "bold",
              color: "#1AB5B8",
            }}
          >
            Due Diligence {selectedIndustry}
          </Typography>
          <Button
            startIcon={!isEditMode ? <Edit size={20} color="#000" /> : <Save size={20} color="#000" />}
            sx={{
              color: "#000",
              fontWeight: "bold",
              px: "20px",
            }}
            onClick={handleEditClick}
          >
            {isEditMode ? "Done" : "Edit"}
          </Button>
        </Stack>

        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <SectionHeaderWithButton title="Phase 01" phase={1} />
            <Grid container ml={0} spacing={8} pb={3} pl={1} gap={3}>
              {workstreamsPhase1.length > 0 ? (
                workstreamsPhase1.map((workstream, index) => (
                  <DueDiligenceCard
                    key={workstream.id}
                    id={workstream.id}
                    title={workstream.name}
                    progressPercentage={workstream.progressPercentage}
                    completedTasks={workstream.completedTasks}
                    inProgressTasks={workstream.inProgressTasks}
                    userInputTasks={workstream.userInputTasks}
                    notStartedTasks={workstream.notStartedTasks}
                    templateTasks={workstream.default_questionnaires[0].default_questions.length}
                    type={index % 2 === 0 ? "inpair" : "pair"}
                    onClick={() => handleCardClick(workstream)}
                    isEditMode={isEditMode}
                    onEdit={handleEditWorkstream}
                    onDelete={handleDeleteWorkstream}
                  />
                ))
              ) : (
                <Typography
                  sx={{
                    marginTop: 6,
                    marginBottom: 2,
                    padding: 2,
                    marginLeft: 10,
                  }}
                >
                  No Template workstreams found for Phase 1
                </Typography>
              )}
            </Grid>
            <SectionHeaderWithButton title="Phase 02" buttonText="Add workstream" onButtonClick={handleDetailsClick2} phase={2} />
            {/* WorkstreamModal component */}
            <WorkstreamModal open={isModalOpen} onClose={handleCloseModal} />

            <Typography
              variant="h6"
              sx={{
                marginTop: 2,
                marginBottom: 2,
                marginLeft: 2,
                fontWeight: "bold",
                color: "#1AB5B8",
              }}
            >
              Workstream
            </Typography>
            <Grid container ml={0} spacing={8} pb={3} pl={1} gap={3}>
              {workstreamsPhase2.length > 0 ? (
                workstreamsPhase2.map((workstream, index) => (
                  <DueDiligenceCard
                    key={workstream.id}
                    id={workstream.id}
                    title={workstream.name}
                    progressPercentage={workstream.progressPercentage}
                    completedTasks={workstream.completedTasks}
                    inProgressTasks={workstream.inProgressTasks}
                    userInputTasks={workstream.userInputTasks}
                    notStartedTasks={workstream.notStartedTasks}
                    templateTasks={workstream.default_questionnaires[0].default_questions.length}
                    type={index % 2 === 0 ? "inpair" : "pair"}
                    onClick={() => handleCardClick(workstream)}
                    isEditMode={isEditMode}
                    onEdit={handleEditWorkstream}
                    onDelete={handleDeleteWorkstream}
                  />
                ))
              ) : (
                <Typography
                  sx={{
                    marginTop: 6,
                    padding: 2,
                    marginLeft: 10,
                  }}
                >
                  No Template workstreams found for Phase 2
                </Typography>
              )}
            </Grid>
            <Grid item sm={4} md={4} lg={2.4} xl={2.1}>
              {/* <Stack spacing={1} pt={3} justifyContent={"center"} alignItems={"center"}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginTop: 2,
                      marginBottom: 2,
                      marginLeft: 2,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Financial model
                  </Typography>
                  <Card
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      overflow: "visible",
                      backgroundColor: "#F5F5F7",
                    }}
                  >
                    <Box sx={{ position: "relative" }}>
                      <CardMedia
                        sx={{
                          height: 140,
                          filter: "blur(5px)",
                        }}
                        image={excelImage}
                        alt="Excel file preview"
                      />
                      <IconButton
                        aria-label="download"
                        sx={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                        }}
                      >
                        <CloudDownload color="black" />
                      </IconButton>
                      <IconButton
                        aria-label="preview"
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <Eye color="black" />
                      </IconButton>
                      <IconButton
                        aria-label="excel"
                        sx={{
                          position: "absolute",
                          top: 5,
                          left: 5,
                        }}
                      >
                        <ExcelIcon />
                      </IconButton>
                    </Box>
                  </Card>
                </Stack> */}
            </Grid>
            {/* <Typography
                variant="h5"
                sx={{
                  marginTop: 4,
                  marginBottom: 2,
                  fontWeight: "bold",
                  color: "#1AB5B8",
                }}
              >
                Internal Documents
              </Typography> */}

            {/* <Stack direction="row" spacing={3}>
                {["Letter of Intent", "Investment Memo"].map((title) => (
                  <Button key={title} variant="contained" startIcon={<FileLock2 />} sx={{ bgcolor: "#1AB5B8", color: "#fff", fontWeight: "bold", px: "20px" }}>
                    {title}
                  </Button>
                ))}
              </Stack> */}
          </>
        )}
      </>
    </Documentstart>
  );
};

export default DueDiligenceTemplate;
