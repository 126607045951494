import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ImportFormModal from "../screens/DataScreen/components/ImportFormModal";
import { useSelector } from "react-redux";
import NoUploadModal from "./NoUploadModal";
import { backendClient } from "../api/backend";
import { MAX_FILES_UPLOAD } from "../utils/constants";
import { Trash, UploadIcon } from "lucide-react";

const Input = styled("input")({
  display: "none",
});

const FilesUpload = ({ uploadedFiles, setUploadedFiles, is_upload }) => {
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const [filesToRemove, setFilesToRemove] = useState([]);
  const [isModalFormVisible, setIsModalFormVisible] = useState(false);
  const [isVisibleModalNoUpload, setIsVisibleModalNoUpload] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [isLoadingDeals, setIsLoadingDeals] = useState(true);
  const [totalDeals, setTotalDeals] = useState(null);

  useEffect(() => {
    const fetch_fn = async () => {
      const totalDeals_ = await backendClient.countTransactions();
      setTotalDeals(totalDeals_["count"]);
      setIsLoadingDeals(false);
    };
    fetch_fn();
  }, []);

  const handleFileSelect = (event, isDrag = false) => {
    const files = isDrag ? event : event.target.files;
    const totalFiles = uploadedFiles.length + files.length;

    // Ensure file limit is not exceeded
    if (totalFiles > MAX_FILES_UPLOAD) {
      alert(`You cannot upload more than ${MAX_FILES_UPLOAD} files in this environment.`);
      return;
    }

    // Filter only PDFs and prevent duplicates
    const newFiles = Array.from(files).filter((file) => {
      return file.type === "application/pdf" && !uploadedFiles.some((uploadedFile) => uploadedFile.name === file.name);
    });

    if (newFiles.length > 0) {
      setUploadedFiles((prev) => [...prev, ...newFiles]);
    }
  };

  const handleNoUploadPossible = () => {
    setIsVisibleModalNoUpload(true);
  };

  const handleRemoveFile = (index) => {
    const newUploadedFiles = [...uploadedFiles];
    const removedFile = newUploadedFiles.splice(index, 1)[0];
    setUploadedFiles(newUploadedFiles);

    if (removedFile) {
      setFilesToRemove([...filesToRemove, removedFile]);
    }
  };

  const handleUpload = async () => {
    const totalFiles = uploadedFiles.length;
    if (totalFiles > MAX_FILES_UPLOAD && user.organization_id !== "k-fin") {
      alert(`You cannot upload more than ${MAX_FILES_UPLOAD} files in this test environment.`);
      return;
    }
    setIsModalFormVisible(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    handleFileSelect(files, true);
  };

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const handleCloseImportModal = () => {
    if (!isSubmitLoading) {
      setIsModalFormVisible(false);
    }
  };

  return (
    <div>
      {/* Drag and Drop Area */}
      <section className="flex flex-col items-center justify-center p-4 h-52 border-2 border-dashed border-muted-foreground/25 rounded-lg cursor-pointer" onDragOver={handleDragOver} onDrop={handleDrop}>
        <NoUploadModal open={isVisibleModalNoUpload} handleClose={() => setIsVisibleModalNoUpload(false)} />
        <ImportFormModal isSubmitLoading={isSubmitLoading} setIsSubmitLoading={setIsSubmitLoading} uploadedFiles={uploadedFiles} open={isModalFormVisible} handleClose={handleCloseImportModal} />

        {user.organization_id === "k-fin" || parseInt(totalDeals) <= 3 ? (
          uploading ? (
            <CircularProgress size={48} thickness={4} />
          ) : (
            <label htmlFor="file-upload-button" className="flex flex-col items-center justify-center h-full mb-2 cursor-pointer">
              <input accept="application/pdf" multiple id="file-upload-button" type="file" onChange={handleFileSelect} style={{ display: "none" }} />
              <div className="flex flex-col items-center justify-center gap-4 sm:px-5 cursor-pointer">
                <div className="rounded-full border border-dashed p-3 ">
                  <UploadIcon className="size-7 text-muted-foreground" aria-hidden="true" />
                </div>
                <p className="font-medium text-sm text-gray-500">
                  Drag and drop files here or <span className="text-blue-500 underline cursor-pointer">click to select files</span>
                </p>
              </div>
            </label>
          )
        ) : (
          <label htmlFor="file-upload-button" className="flex flex-col items-center justify-center h-full mb-2 cursor-pointer">
            <input accept="application/pdf" multiple id="file-upload-button" type="file" onChange={handleFileSelect} style={{ display: "none" }} />
            <div className="flex flex-col items-center justify-center gap-4 sm:px-5">
              <div className="rounded-full border border-dashed p-3">
                <UploadIcon className="size-7 text-muted-foreground" aria-hidden="true" />
              </div>
              <p className="font-medium text-sm text-gray-500 cursor-pointer">
                Drag and drop files here or <span className="text-blue-500 underline cursor-pointer">click to select files</span>
              </p>
            </div>
          </label>
        )}
      </section>

      <div style={{ marginTop: "20px" }}></div>

      {/* Add Files and Upload Buttons */}
      <div className="flex justify-end items-center space-x-2">
        {uploadedFiles?.length > 0 && (
          <Button
            variant="outlined"
            onClick={() => setUploadedFiles([])}
            sx={{
              width: "200px",
              height: "50px",
              bgcolor: "white",
              color: "black",
              boxShadow: "none",
              borderRadius: "8px",
              "&:hover": { bgcolor: "white", color: "black" },
            }}
          >
            <Trash className="mr-2" /> Cancel Selection
          </Button>
        )}

        {uploadedFiles?.length > 0 && is_upload && (
          <Button
            variant="outlined"
            onClick={handleUpload}
            sx={{
              width: "200px",
              height: "50px",
              bgcolor: "black",
              color: "white",
              boxShadow: "none",
              borderRadius: "8px",
              "&:hover": { bgcolor: "black", color: "white" },
            }}
          >
            Upload
          </Button>
        )}
      </div>
    </div>
  );
};

export default FilesUpload;
