import React, { useEffect, useRef, useState } from "react";
// import { MESSAGE_STATUS, ROLE } from "~/types/conversation";
// import { LoadingSpinner } from "~/components/basics/Loading";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { PiCaretDownBold } from "react-icons/pi";
// import { usePdfFocus } from "~/context/pdf";
import { AiFillExclamationCircle } from "react-icons/ai";
import { usePdfFocus } from "../../../components/pdf-viewer/pdfContext";
// import { borderColors } from "~/utils/colors";
// import { formatDisplayDate } from "~/utils/timezone";
import StarBorderIcon from "@mui/icons-material/StarRate";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Avatar, Box, Button, Card, CardContent, CircularProgress, Divider, Grid, Stack, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import * as pdfjsLib from "pdfjs-dist/webpack";
import { useDispatch, useSelector } from "react-redux";
import DanIcon from "../../../assets/chat/dan.png";
import { setActivePdf, setExploreCitation, setPdfLoadingTask } from "../../../redux/actions/documentsAction";
import { colors } from "../../../utils/colors";
import { MESSAGE_STATUS, ROLE } from "../../../utils/constants";
import { formatDisplayDate } from "../../../utils/timezone";
import { findDocument, getDocumentById, markdownToHtmlWithParagraphs, windowHeight } from "../../../utils/utils";
import AnimatedSteps from "./AnimatedSteps";

import { updateMessageLike } from "../../../redux/actions/conversationAction";

const formatText = (text) => {
  // Replace **text** with bold
  text = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

  // Replace *text* with italic
  text = text.replace(/\*(.*?)\*/g, "<em>$1</em>");

  // Replace _text_ with underline
  text = text.replace(/_(.*?)_/g, "<u>$1</u>");

  // Replace `text` with code
  text = text.replace(/`(.*?)`/g, "<code>$1</code>");

  // Replace URLs with clickable links
  text = text.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');

  return text;
};

const CitationDisplay = ({ citation, isSelected, onSelect }) => {
  const { setPdfFocusState } = usePdfFocus();
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.documents.documents);
  const loadingTask = useSelector((state) => state.documents.loadingTask);

  const handleCitationClick = async () => {
    const { documentId, pageNumber } = citation;
    const foundDoc = findDocument(documents, documentId);

    if (!foundDoc) {
      console.error("Document not found");
      return;
    }

    try {
      if (loadingTask?.destroy) {
        await loadingTask.destroy();
      }

      const newLoadingTask = pdfjsLib.getDocument(foundDoc.url);
      await dispatch(setPdfLoadingTask(newLoadingTask));

      const document = getDocumentById(documents, documentId);
      await dispatch(setActivePdf(document));

      await new Promise((resolve) => setTimeout(resolve, 1000));

      await newLoadingTask.promise;

      const citationData = {
        documentId,
        pageNumber: pageNumber + 1,
        citation: citation.snippet,
      };

      await dispatch(setExploreCitation(citationData));
      setPdfFocusState(citationData);
    } catch (error) {
      console.error("Failed to load PDF:", error);
      // TODO: Handle error (e.g., show error notification)
    }
  };

  const citationClass = isSelected ? "border-purple-500" : colors.GRAY_TWO;

  return (
    <div className={`mx-1.5 mb-2 min-h-[25px] min-w-[160px] cursor-pointer rounded border-l-8 bg-gray-00 p-1 hover:bg-gray-15 ${citationClass}`} onClick={handleCitationClick}>
      <div className="flex items-center">
        <div className="mr-1 text-xs font-bold text-[#B81A80] overflow-hidden whitespace-nowrap text-overflow-ellipsis flex-grow">{citation.ticker}</div>
        <div className="text-[10px] flex-shrink-0">p. {citation.pageNumber + 1}</div>
      </div>
      <p className="line-clamp-2 text-[10px] font-light leading-3">{citation.snippet}</p>
    </div>
  );
};

const SubProcessDisplay = ({ subProcesses, like, isOpen, toggleOpen, messageId, showSpinner }) => {
  const subQuestions = [];
  subProcesses?.forEach((subProcess, subProcessIndex) => {
    if (subProcess.metadata_map?.sub_question) {
      subQuestions.push({
        subQuestion: subProcess.metadata_map?.sub_question,
        subProcessIndex,
        subQuestionIndex: subQuestions.length,
      });
    } else if (subProcess.metadata_map?.sub_questions) {
      subProcess.metadata_map?.sub_questions.forEach((subQuestion) => {
        subQuestions.push({
          subQuestion,
          subProcessIndex,
          subQuestionIndex: subQuestions.length,
        });
      });
    }
  });

  const [selected, setSelected] = useState(null);
  const [reviewed, setReviewed] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);
  const [fadeClass, setFadeClass] = useState("fade-out");
  const [isLikeDisabled, setIsLikeDisabled] = useState(false);

  const dispatch = useDispatch();

  const [selectedCitationText, setSelectedCitationText] = useState(null);
  const handleCitationSelect = (citation) => {
    console.log("handleCitationSelect", citation);
    setSelectedCitationText(citation.text);
    // setPdfFocusState({ documentId, pageNumber: pageNumber, citation: citation});
  };

  const [fetchedDocuments, setFetchedDocuments] = useState([]);

  useEffect(() => {
    if (like?.value === "False") {
      setSelected("down");
      setIsLikeDisabled(true);
    } else if (like?.value === "True") {
      setSelected("up");
      setIsLikeDisabled(true);
    }
  }, []);

  const subQuestionStepsArray = [
    { step: "1", text: "Querying tree" },
    { step: "2", text: "Expanding query" },
    { step: "3", text: "Asking subquestion" },
    { step: "4", text: "Answering subquestion" },
    { step: "5", text: "Summarize analysis" },
    { step: "6", text: "Checking results" },
    //   { step: "7", text: "Sending to user" },
  ];
  const answerStepsArray = [
    { step: "1", text: "Querying tree" },
    { step: "2", text: "Expanding query" },
    // { step: "3", text: "Answering subquestion" },
    // { step: "4", text: "Checking results" },
    //   { step: "7", text: "Sending to user" },
  ];

  const handleSelection = async (choice) => {
    setSelected(selected === choice ? null : choice);
    if (choice === "down") {
      setMessageVisible(false);
    } else {
      setMessageVisible(true);
      await dispatch(updateMessageLike(messageId, true, "N/A"));

      // setReviewed(true);
      setFadeClass("fade-out");

      // Set a timeout to fade out the message after 2 seconds
      setTimeout(() => {
        setFadeClass("hidden");
      }, 500);

      // Set a timeout to hide the message completely after 4 seconds
      setTimeout(() => {
        setMessageVisible(false);
      }, 600);
    }
  };

  // Function to handle button click
  const handleReviewClick = async (option) => {
    await dispatch(updateMessageLike(messageId, false, option));

    // setReviewed(true);
    setFadeClass("fade-out");

    // Set a timeout to fade out the message after 2 seconds
    setTimeout(() => {
      setFadeClass("hidden");
    }, 1000);

    // Set a timeout to hide the message completely after 4 seconds
    setTimeout(() => {
      setMessageVisible(false);
    }, 2000);
  };

  const [isStarSelected, setIsStarSelected] = useState(false); // State for star selection
  const handleStarClick = () => {
    setIsStarSelected((prev) => !prev); // Toggle star selection
  };

  const options = ["Shouldn't have used Memory", "Didn't fully follow instructions", "Don't like the style", "Not factually correct", "Refused when it shouldn't have", "More..."];
  return (
    <div key={`${messageId}-sub-process`} className="mt-4 w-full rounded ">
      <div className="text-gray-90 text-sm">Let me check the knowledge base for you. One moment!</div>
      <div className="flex justify-between w-full">
        <div className="flex w-max cursor-pointer items-center rounded p-1 font-nunito text-sm text-[#B81A80] font-bold hover:bg-gray-00" onClick={() => toggleOpen()}>
          View progress
          <div className="px-3 py-2">{isOpen ? <PiCaretDownBold /> : <PiCaretDownBold className="-rotate-90" />}</div>
        </div>
        <div className="flex items-center">
          <IconButton
            onClick={handleStarClick}
            aria-label="star"
            style={{ color: isStarSelected ? "#1976d2" : "#6c6c6d" }} // Set to yellow when selected
          >
            <StarBorderIcon style={{ fontSize: 24 }} />
          </IconButton>
          <IconButton onClick={async () => await handleSelection("up")} aria-label="thumb up" color={selected === "up" ? "primary" : "default"}>
            <ThumbUpIcon style={{ fontSize: 18 }} />
          </IconButton>
          <IconButton onClick={async () => await handleSelection("down")} aria-label="thumb down" color={selected === "down" ? "primary" : "default"}>
            <ThumbDownIcon style={{ fontSize: 18 }} />
          </IconButton>
        </div>
      </div>
      {(!isLikeDisabled || !messageVisible) && selected === "down" && (
        <Box sx={{ background: "#FFF", padding: 1, border: "0.1px solid #FFFFF", borderRadius: 2 }}>
          <h2 className="text-xs font-bold text-gray-600">{"Tell us more:"}</h2>
          <Grid container spacing={2} sx={{ marginTop: -0.5 }}>
            {options.map((option, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Button
                  onClick={async () => {
                    setIsLikeDisabled(true);
                    setMessageVisible(true);
                    setTimeout(async () => {
                      // Perform your action here
                      console.log("States should be updated now");
                      await handleReviewClick(option);
                    }, 0);
                  }}
                  variant="outlined"
                  fullWidth
                  sx={{ border: "0.1px solid #797E90", color: "grey", textTransform: "none" }}
                >
                  <h2 className="text-xs font-bold text-red-600">{option}</h2>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {messageVisible && isLikeDisabled && <h2 sx={{ padding: 1, border: "0.1px solid #FFFFF", borderRadius: 2, fontSize: "1rem", fontWeight: "bold", color: "gray.600", transition: "opacity 2s ease-out" }}>{"Thank you!"}</h2>}
      {isOpen && (
        <>
          <div className="ml-1 border-l pb-1 pl-4 font-nunito text-[14px] text-black font-bold">
            <div>Question Received</div>
            {subQuestions.length > 0 && (
              <div key={`${messageId}-sub-process`} className="text-gray-60">
                <div>
                  {subQuestions.length === 0 && <AnimatedSteps stepsArray={subQuestionStepsArray} />}
                  {subQuestions.map(({ subQuestion, subQuestionIndex, subProcessIndex }) => {
                    const hasCitations = !!subQuestion.citations;
                    return (
                      <div key={`${messageId}-${subProcessIndex}-${subQuestionIndex}`}>
                        Generated Sub Query #{subQuestionIndex + 1}{" "}
                        <div className="flex w-11/12 flex-col rounded border">
                          <div className="rounded bg-[#B81A80] p-2 font-bold text-gray-100">{subQuestion.question}</div>
                          {subQuestion.answer ? (
                            <div className="overflow-scroll p-2 text-[13px] font-light">{subQuestion.answer}</div>
                          ) : (
                            <>
                              {!hasCitations && (
                                <div className="flex justify-center">
                                  <AnimatedSteps stepsArray={answerStepsArray} />
                                </div>
                              )}
                            </>
                          )}

                          {hasCitations && (
                            <div className=" mr-2 flex w-full overflow-scroll pl-2 ">
                              {subQuestion.citations?.map((citation, citationIndex) => {
                                const yearDisplay = "";
                                const isSelected = citation.text === selectedCitationText;
                                return (
                                  <CitationDisplay
                                    key={`${messageId}-${subProcessIndex}-${subQuestionIndex}-${citationIndex}`}
                                    isSelected={isSelected}
                                    onSelect={() => handleCitationSelect(citation)}
                                    citation={{
                                      documentId: citation.document_id,
                                      snippet: citation.text,
                                      pageNumber: citation.page_number,
                                      // ticker: citationDocument?.ticker,
                                      citation: citation,
                                      ticker: citation.document_name,
                                      displayDate: yearDisplay,
                                      color: citation.color,
                                    }}
                                  />
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          {showSpinner && (
            <div className="ml-2.5 mt-1 ">
              {/* <div className="loader h-3 w-3 rounded-full border-2 border-gray-200 ease-linear"></div> */}
              <CircularProgress size={windowHeight / 42} />
            </div>
          )}
          <div className="pb-2"></div>
        </>
      )}
    </div>
  );
};

const UserDisplay = ({ message, showLoading }) => {
  return (
    <>
      <div className="flex border-r bg-gray-00 pb-4">
        <div className="mt-4 w-1/5 flex-grow text-right font-nunito text-gray-60">
          <div className="flex items-center justify-center">{formatDisplayDate(message?.created_at)}</div>
        </div>
        <div className="mt-4 w-4/5 pr-3 font-nunito font-bold text-gray-90">{message?.content}</div>
      </div>

      {showLoading && (
        <div className="flex border-b-2 pb-4">
          <div className="w-1/5"></div>
          <div className="w-4/5">
            <SubProcessDisplay key={`${message?.id}-loading-sub-process`} messageId={message?.id} subProcesses={[]} isOpen={true} toggleOpen={() => {}} showSpinner={showLoading} />
          </div>
        </div>
      )}
    </>
  );
};

const UserDisplayNew = ({ message, showLoading }) => {
  return (
    <Stack spacing={2} alignItems="flex-end" width="100%">
      {/* User message section */}
      <Stack spacing={2} alignItems="flex-end" width="100%">
        {/* User message section */}
        <Stack direction="row" spacing={2} alignItems="flex-end" justifyContent="flex-end" width="100%">
          {/* Message Content */}
          <Stack minWidth="100px" maxWidth="65%" alignItems="flex-end">
            <Card
              sx={{
                bgcolor: "#f5f5f5",
                borderRadius: "16px",
                width: "100%",
              }}
            >
              <CardContent
                sx={{
                  padding: "8px", // Control padding to remove excess space
                  position: "relative", // Set relative position to position date
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: "bold", color: "#424242", mb: 1 }}>
                  {message?.content}
                </Typography>

                {/* Date at the bottom-right */}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "8px", // Align the date at the bottom
                    right: "8px", // Align the date to the right
                  }}
                >
                  <Typography variant="caption" color="textSecondary">
                    {formatDisplayDate(message?.created_at)}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Stack>

          {/* Avatar */}
          <Avatar alt="User Avatar" src={message?.avatarUrl} sx={{ mb: 1 }} />
        </Stack>
      </Stack>

      {/* Loading state (if applicable) */}
      {showLoading && (
        <Stack
          direction="row"
          alignItems="flex-start"
          sx={{
            borderBottom: "2px solid #e0e0e0",
            pb: 2,
            mt: 2,
            width: "100%",
          }}
        >
          {/* Empty space for alignment */}
          <div style={{ width: "20%" }}></div>
          <Stack width="80%" pl={2}>
            <SubProcessDisplay key={`${message?.id}-loading-sub-process`} messageId={message?.id} subProcesses={[]} isOpen={true} toggleOpen={() => {}} showSpinner={showLoading} />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

const ErrorMessageDisplay = () => {
  return (
    <div className="mt-2 flex w-[80%] items-center rounded border border-red-500 bg-red-100 bg-opacity-20 p-1">
      <div className="ml-2">
        <AiFillExclamationCircle className="fill-red-500" size={20} />
      </div>
      <div className="ml-4 text-red-400">Error: please try again later or reach out to support.</div>
    </div>
  );
};

const AssistantDisplay = ({ message, documentsIds, showLoading }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const isMessageSuccessful = message?.status === MESSAGE_STATUS.SUCCESS;
  const isMessageError = message?.status === MESSAGE_STATUS.ERROR;

  useEffect(() => {
    if (isMessageSuccessful) {
      setIsExpanded(false);
    }
  }, [isMessageSuccessful]);

  const styleSheet = `
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 1s ease-out forwards;
}
`;
  const getChatMode = (chat_mode) => {
    switch (chat_mode?.toLowerCase()) {
      case "dan":
        return "DAN";
      case "oneshot":
        return "OA";
      default:
        return "OA";
    }
  };
  const tooltipTitle = getChatMode(message.chat_mode) === "DAN" ? "Diligent Analytical Neural-Network" : "Conversation Agent";

  return (
    <div className="border-b pb-4">
      <div className="flex ">
        <>
          <style>{styleSheet}</style>
          <div className="w-[70px] flex flex-col justify-center items-center text-4xl fade-in-up">
            {/* <div style={{ fontSize: "2.5rem" }}>🧒</div> */}
            <img src={DanIcon} alt="DanIcon" className="h-16" />
            <Tooltip title={tooltipTitle} placement="bottom">
              <span style={{ marginTop: windowHeight / 180, background: "#000", textAlign: "center", justifyContent: "center", alignItems: "center", alignContent: "center", color: "#fff", height: windowHeight / 45, padding: "0.5px 10px", borderRadius: "2px", fontSize: "8px", display: "flex" }}>
                {getChatMode(message.chat_mode)}
              </span>
            </Tooltip>
          </div>
        </>

        <div className="w-4/5">
          {!isMessageError && (
            <div className="flex flex-col">
              <SubProcessDisplay key={`${message?.id}-sub-process`} like={message?.like} subProcesses={message?.sub_processes || []} isOpen={isExpanded} toggleOpen={() => setIsExpanded((prev) => !prev)} showSpinner={!isMessageSuccessful} messageId={message?.id} documentsIds={documentsIds} />
            </div>
          )}
          {isMessageError && <ErrorMessageDisplay />}
        </div>
      </div>

      {!isMessageError && (
        <>
          <div className="flex items-center justify-center">
            <div className="my-3 w-11/12 border-[.5px]"></div>
          </div>
          <div className="flex ">
            <div className="w-[70px]"></div>
            <div className="w-4/5">
              {/* <p className="relative mb-2 mt-2 whitespace-pre-wrap pr-3 font-nunito font-bold text-gray-90">{markdownToHtmlWithParagraphs(message?.content)}</p> */}
              <div className="relative mb-2 mt-2 whitespace-pre-wrap pr-3 font-nunito font-bold text-gray-90" dangerouslySetInnerHTML={{ __html: markdownToHtmlWithParagraphs(message?.content) }} />
              {/* <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> */}
              {message.chat_mode === "oneshot" && <p className="flex items-center justify-start p-1 text-xs text-gray-60">The conversation agent offers quick, partial responses; for full answers, use the DAN.</p>}
              <p className="flex items-center justify-start p-1 text-xs text-gray-60">We encourage you to verify every source before using the answer in your work.</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const AssistantDisplayNew = ({ message, documentsIds }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const isMessageSuccessful = message?.status === MESSAGE_STATUS.SUCCESS;
  const isMessageError = message?.status === MESSAGE_STATUS.ERROR;

  useEffect(() => {
    if (isMessageSuccessful) {
      setIsExpanded(false);
    }
  }, [isMessageSuccessful]);

  const getChatMode = (chat_mode) => {
    switch (chat_mode?.toLowerCase()) {
      case "dan":
        return "DAN";
      case "oneshot":
        return "OA";
      default:
        return "DAN";
    }
  };

  const tooltipTitle = getChatMode(message.chat_mode) === "DAN" ? "Diligent Analytical Neural-Network" : "Conversation Agent";

  return (
    <Stack direction="row" spacing={2} alignItems="flex-start" width="100%">
      {/* Avatar with Tooltip */}
      <Tooltip title={tooltipTitle} placement="bottom">
        <Box>
          <img src={DanIcon} alt="DanIcon" className="h-16" />
          <Typography
            variant="caption"
            sx={{
              background: "#000",
              color: "#fff",
              padding: "2px 8px",
              borderRadius: "4px",
              textAlign: "center",
              display: "block",
              marginTop: "4px",
            }}
          >
            {getChatMode(message.chat_mode)}
          </Typography>
        </Box>
      </Tooltip>

      {/* Message Content */}
      <Box width="100%">
        <Card
          style={{ flex: 1 }}
          sx={{
            background: "#F5F5F7",
            borderRadius: "16px",
          }}
        >
          <CardContent>
            {!isMessageError ? (
              <SubProcessDisplay key={`${message?.id}-sub-process`} like={message?.like} subProcesses={message?.sub_processes || []} isOpen={isExpanded} toggleOpen={() => setIsExpanded((prev) => !prev)} showSpinner={!isMessageSuccessful} messageId={message?.id} documentsIds={documentsIds} />
            ) : (
              <ErrorMessageDisplay />
            )}

            {!isMessageError && (
              <>
                <Divider style={{ margin: "16px 0" }} />
                <Typography variant="body2" dangerouslySetInnerHTML={{ __html: markdownToHtmlWithParagraphs(message?.content) }} style={{ marginBottom: "8px" }} />
                {message.chat_mode === "oneshot" && (
                  <Typography variant="caption" color="textSecondary">
                    The conversation agent offers quick, partial responses; for full answers, use the DAN.
                  </Typography>
                )}
                <Typography variant="caption" color="textSecondary">
                  We encourage you to verify every source before using the answer in your work.
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
      </Box>
    </Stack>
  );
};

export const RenderConversations = ({ messages, setUserMessage }) => {
  const lastElementRef = useRef(null);

  useEffect(() => {
    if (lastElementRef.current) {
      lastElementRef.current.scrollIntoView();
    }
  }, [messages]);

  const showLoading = messages[messages.length - 1]?.role === ROLE.USER;
  console.log(showLoading);
  console.log(messages);
  return (
    <div className="box-border flex h-full flex-col justify-start font-nunito text-sm text-[#2B3175]">
      {messages.map((message, index) => {
        let display;
        if (message?.role === ROLE.ASSISTANT) {
          display = <AssistantDisplayNew message={message} key={`${message?.id}-answer-${index}`} showLoading={index === messages.length - 1 ? showLoading : false} />;
          console.log(display);
        } else if (message?.role === ROLE.USER) {
          display = <UserDisplayNew message={message} key={`${message?.id}-question-${index}-user`} showLoading={index === messages.length - 1 ? showLoading : false} />;
        } else {
          display = <div onClick={() => console.log("messages", JSON.parse(message))}>Sorry, there is a problem.</div>;
        }

        const isEven = index % 2 === 0;
        const cardClass = "transparant";

        return (
          <div className={`flex flex-col mb-4 p-4 rounded-lg ${cardClass}`} key={`${message?.id}-${index}`}>
            {display}
          </div>
        );
      })}
      {messages.length === 0 && (
        <div className="flex h-full items-center justify-center">
          <div className="flex w-full flex-col items-center justify-center">
            <div>
              <HiOutlineChatAlt2 size={40} />
            </div>
            <div className="mb-2 w-3/4 text-center text-lg font-bold">Ask DueDil.ai questions about the documents you've selected, such as:</div>
            <div className="m-auto flex w-full flex-wrap justify-center">
              <button onClick={() => setUserMessage("What is the PPA Price?")} className="m-1 flex-shrink rounded-full border border-gray-60 px-3 py-1 hover:bg-gray-15">
                What is the PPA Price?
              </button>
              <button onClick={() => setUserMessage("What is the margin on the debt?")} className="m-1 flex-shrink rounded-full border border-gray-60 px-3 py-1 hover:bg-gray-15">
                What is the margin on the debt?
              </button>
              <button onClick={() => setUserMessage("What is the production of the transaction?")} className="m-1 flex-shrink rounded-full border border-gray-60 px-3 py-1 hover:bg-gray-15">
                What is the production of the transaction
              </button>
            </div>
          </div>
        </div>
      )}
      <div ref={lastElementRef}></div>
    </div>
  );
};
